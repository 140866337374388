import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as React from "react"
import styled from "styled-components"
import Layout from "../components/Layout"
import Navbar from "../components/Navbar"
import Seo from "../components/Seo"
import { Header1 } from "../theme/typography"

const PhotoGrid = styled.div`
  /*   display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 24px;
  justify-items: center;
  align-items: center; 
  max-width: 1200px;
  margin: 0 auto;

  column-count: 3;
  column-gap: 24px;
  */
`

const Photo = styled(GatsbyImage)`
  border-radius: 8px;
  overflow: hidden;
  max-height: 100vh;
  display: inline-block;
  width: 100%;
  margin: 0 0 24px;
  width: 100%;

  > * {
    border-radius: 8px;
    overflow: hidden;
  }
`

const PhotoGridWrapper = styled.div`
  display: grid;
  justify-items: center;
  grid-auto-flow: row;
  gap: 32px;
  padding: 32px 0;

  justify-self: center;

  > ${Header1} {
    color: var(--base12);
    display: block;
    text-align: start;
    width: 100%;
  }
`

const PhotoCollectionTemplate = ({ data }) => {
  const photoCollection = data.contentfulPhotoCollection

  return (
    <Layout>
      <Seo title={photoCollection.title} />
      <Navbar />
      <PhotoGridWrapper>
        <Header1>{photoCollection.title}</Header1>
        <PhotoGrid>
          {photoCollection.images.map(image => {
            let portrait

            const height = image.file.details.image.height
            const width = image.file.details.image.width

            if (height / width > 1) {
              portrait = true
            } else {
              portrait = false
            }

            return (
              <Photo
                image={image.gatsbyImageData}
                alt={image.title}
                key={image.id}
                imgStyle={{ borderRadius: 8 }}
                portrait={portrait}
              />
            )
          })}
        </PhotoGrid>
      </PhotoGridWrapper>
    </Layout>
  )
}

export default PhotoCollectionTemplate

export const query = graphql`
  query ($id: String) {
    contentfulPhotoCollection(id: { eq: $id }) {
      title
      contentful_id
      images {
        title
        gatsbyImageData(layout: CONSTRAINED, width: 1600, quality: 100)
        id
        file {
          details {
            image {
              height
              width
            }
          }
        }
      }
    }
  }
`
